import './Inspire.css';
import React, { useEffect, useState, useRef } from 'react';
import { TalkingHead } from "../utils/Talkinghead";
import { greetUserOpenAI, transcribeAudioOpenAI, describeImageOpenAI, respondToUserOpenAI, checkQuizAnswer } from '../utils/OpenAI';
import { chatCompletionGroq } from '../utils/Groq';
import systemPrompt from '../utils/systemPromptInspire';
import * as faceapi from 'face-api.js';
import axios from 'axios';


let head = null;
let loaded=false;
let timeout=null;
let conversation=[];
let audioChunks=[];
let mediaRecorder=null;
let audioContext;
let audioSource;
let audioStream;
let analyser;
let isRecording=false;
let dataArray=[];
let silenceCounter=0;
let isWaitingForTranscriptionResult=false;
let isWaitingForDescriptionResult=false;
let isAudioActive=false;
let faceBuffer=0;
let isAvatarThinking=false; // Between ending the recording and the text being spoken

// Store the latest image and recording
let latestImage=null;
let latestImageDescription=null;
let latestRecording=null;

let LLMService = 'openai';
let consentVideoMicrophone = false;
let showPopup = true; // Consent for video and microphone



function App() {
  const videoRef = useRef();
  const [recordingIndicator, setRecordingIndicator] = useState(false);
  const [pauseButtonText, setPauseButtonText] = useState('Pauze');
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);

  useEffect(() => {
    if (!loaded) {
      loadAvatar();
      loadModels();
    }
    if (consentVideoMicrophone){
      // Start the video stream
      setTimeout(() => {
        // Start the video stream
        startVideo();
      }, 1000);
      setConsentVideoMicrophone(false);
      showPopup = false;
    }
  }, [consentVideoMicrophone]);

  const loadModels = async () => {
    // Load face-api.js models
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
    await faceapi.nets.faceExpressionNet.loadFromUri('/models');

    
  };

  // Starts the videofeed.
  const startVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: {} })
      .then(stream => {
        videoRef.current.srcObject = stream;
        videoRef.muted = true;
      })
      .catch(err => console.error('Error accessing webcam: ', err));
  };

  // Makes an image every 500 ms and checks if there are faces in the image.
  const handleVideoPlay = async () => {
    const options = new faceapi.TinyFaceDetectorOptions();
    setInterval(async () => {
      if (videoRef.current) {
        // Check whether any faces are detected in the video feed
        const detections = await faceapi.detectAllFaces(videoRef.current, options);
        
        // Create image from the video feed
        const video=videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL('image/jpg');
        latestImage=image;
 
        // Faces have been detected
      if (detections.length > 0 && timeout === null && !isAudioActive && !head.isSpeaking && !isAvatarThinking) {
          clearTimeout(timeout);
          timeout=setTimeout(greetUser,2000);
          faceBuffer=0;
      }
      // Faces are not detected. Cancelling the greeting in case it is set to launch
      else if (detections.length === 0) {
        console.log("No face detected");
        if (faceBuffer > 5){
          clearTimeout(timeout);
        }
        timeout = null;
        faceBuffer++;
      }
      // avatar is speaking and faces are being detected, reset the facebuffer
      else {
        faceBuffer=0;
      }

      if (faceBuffer>30) {
        conversation = [];
        if (isAudioActive) {
          console.log("No face detected, stopping audio");
          closeAudio();
        }
      }
    }
    }, 500);
  };


  // Send the first greeting after noticing a face
  const greetUser = async () => {
    console.log("Greeting the user");
    isAvatarThinking= true;

    let text='';
    const messages = [{role: "system", content: `Begroet de persoon in de afbeelding en Stel jezelf voor als Robin, een virtueel persoon in het Nederlands. Voel je vrij om op een respectvolle manier aspecten van de afbeelding te betrekken. FORMAT: Output as JSON in the following format: {response: 'text'}`},
      {role: "user", content: [{type: "image_url", image_url: {url: latestImage}}]}];


    if (LLMService === 'openai'){
      text = await greetUserOpenAI(messages);
    } else if (LLMService === 'groq'){
      // ########## GROQ toepassing
      text = await chatCompletionGroq(messages);
    }

    // If the text is not empty, start the conversation
    if (text !== ''){
      // Speak the text
      head.speakText(text);

      conversation = [{role:"system", content: systemPrompt},
                  {role: "assistant", content: text}];
    
      //await elevenSpeak(text);

      // Start the audio
      initAudio();
    }
  };
  
  
  // Loads the avatar on the screen.
  const loadAvatar=async function loadAvatar() {
    if (loaded) { 
      return;
    }
    loaded=true;

      // Instantiate the class
      // NOTE: Never put your API key in a client-side code unless you know
      //       that you are the only one to have access to that code!
      const nodeAvatar = document.getElementById('avatar');
      head = new TalkingHead(nodeAvatar, {
        ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
        ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY, 
        lipsyncModules: ["en"],
        cameraView: "upper"
      });

      // Load and show the avatar
      const nodeLoading = document.getElementById('loading');
      try {
        nodeLoading.textContent = "Loading...";
        await head.showAvatar({
          // Url can be changed for other models
          //url: 'https://models.readyplayer.me/669a5e4c7b8266b463ef1261.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
          // body: 'F',
          // ttsVoice: "en-GB-Standard-A",
          url: 'https://models.readyplayer.me/66cdc08193aa91992c3b8bd0.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
          body: 'M',
          ttsVoice: "nl-NL-Standard-C",
          avatarMood: 'neutral',
          ttsLang: "nl-NL",
          
          lipsyncLang: 'nl'
        }, (ev) => {
          if (ev.lengthComputable) {
            let val = Math.min(1000, Math.round(ev.loaded / ev.total * 100));
            nodeLoading.textContent = "Loading " + val + "%";
          }
   
        });
        nodeLoading.style.display = 'none';
      } catch (error) {
        nodeLoading.textContent = error.toString();
      }     
  }

  // Setup the microphone and start recording
  const initAudio = async () => {
    try {
      audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder = new MediaRecorder(audioStream, { mimeType: 'audio/webm' });
      
      const context = new (window.AudioContext || window.webkitAudioContext)();
      audioContext=context;

      const analyserNode = context.createAnalyser();
      analyser=analyserNode;

      audioSource = context.createMediaStreamSource(audioStream);
      audioSource.connect(analyserNode);

      const bufferLength = analyserNode.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);

      // Check if the setSinkId method is supported
      if ('setSinkId' in HTMLMediaElement.prototype) {
        const audioElement = new Audio();
        await audioElement.setSinkId('default'); // 'default' directs audio to the standard media speaker

        audioElement.play();
      } else {
        console.warn('setSinkId is not supported on this device/browser');
      }

      isAudioActive=true;
      
      // Add recorded chunks together
      mediaRecorder.ondataavailable = event => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      // create a blob variable when the recorder is stopped.
      mediaRecorder.onstop = async () => {
        latestRecording = new Blob(audioChunks, { type: 'audio/webm' });

        // If the recording has finished, send the audio data to be transcribed
        if (head.stateName!=='speaking' && isAudioActive && !isAvatarThinking) {
          isAvatarThinking=true;

          const audioTranscription = await TranscribeAudio();

          respondToUser(audioTranscription);
          

        }
        audioChunks = [];
      };
      isAvatarThinking=false;
      startAudioAnalyzing();
    } catch (err) {
      console.error('Error accessing the microphone', err);
    }
  };


  // Start recording based on the audio input and activity of the avatar
  const startRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'inactive' && !head.isSpeaking && !isAvatarThinking) {
      mediaRecorder.start();
      isRecording=true;
      console.log('Started recording');

      // Save the current image and make a description of the image
      DescribeImage();
    }
  };

  // Stop the recording
  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      setRecordingIndicator(false);
      isRecording=false;
      console.log('Stopped recording');
    }
  };

  // Close the audio stream, triggered when no faces are detected. Also resets the conversation history.
  const closeAudio = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
    }
  
    if (audioContext) {
      audioContext.close();
    }
  
    if (analyser) {
      analyser.disconnect();
    }
  
    if (audioSource) {
      audioSource.disconnect();
    }
  
    if (audioStream) {
      audioStream.getTracks().forEach(track => track.stop());
    }
  
    isAudioActive = false;
    conversation = [];
  };


  // Constantly check whether there is enough noise to start recording, or too little to finish recording.
  const startAudioAnalyzing = () => {
    console.log("Starting audio analysis: ",head.state);

    // Stop recording the user when the avatar is speaking
    if (head.state==='talking' && isRecording) {
      stopRecording();
    }

    // Dont analyze audio when the avatar is speaking
    if (head.state==='talking') {
      return;
    }

    // If audio initializer was succesfull, start analyzing the audio
    if (analyser && dataArray) {

      // Check if the audio has been silent for a certain amount of time
      const checkSilence = () => {

        if (head.state==='talking' || head.isSpeaking || isAvatarThinking) {
          setRecordingIndicator(false);
        } else {
          setRecordingIndicator(true);
        }



        analyser.getByteTimeDomainData(dataArray);
        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
          sum += Math.abs(dataArray[i] - 128);
        }

        // Average volume of the audio
        const average = sum / dataArray.length;

        // If the average volume is below 5, the audio is considered silent
        if (((average < 4) && isRecording)) {
          silenceCounter++;
        } else if (average > 4 && isRecording) {
          silenceCounter=0;
        }



        // If the average volume is above 5, the silence counter is reset
        if (average > 4 && !isRecording) {
          if (silenceCounter>0) {
            silenceCounter=0;
          }
          else {
            silenceCounter--;
          }

          // If the silence counter is below -1, start recording
          if (silenceCounter < -1 && !(head.state==='talking')) {
            startRecording();
          }
        }
        // If the user has been silent for a certain amount of time, stop recording
        else if (silenceCounter > 100) { // Adjust this threshold based on testing
          stopRecording();
        }  
        
        requestAnimationFrame(checkSilence);
      };
      checkSilence();
    }
  };

// Send the audio to OpenAI for transcription
const TranscribeAudio = async () => {
  if (isWaitingForTranscriptionResult) {
    return;
  }
  isWaitingForTranscriptionResult=true;
  

  return transcribeAudioOpenAI(latestRecording);
};


// Describes the image of the user everytime the recording is started. This way a description is ready for the conversation.
const DescribeImage = async () => {
  if (isWaitingForDescriptionResult) {
    return;
  }

  isWaitingForDescriptionResult=true;
  console.log("Describing the image");

  const messages = [{role: "system", content: `Beschrijf de persoon in de afbeelding in het Nederlands. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response"`},
    {role: "user", content: [{type: "image_url", image_url: {url: latestImage}}]}];

  if (LLMService === 'openai'){
    // Getting the base64 string
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponse`, {messages: messages});
    latestImageDescription = response.data.response;
  } else if (LLMService === 'groq'){
    // ############ GROQ toepassing
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponseGroq`, {messages: messages});
    latestImageDescription = response.data.response;
  }
}
  
  
// Make a response based on the image of the user and the transcription of the user
async function respondToUser(transcription) {
  
  console.log("Getting response to answer the user");
  try {
    let messages = conversation;
    messages.push({role: "user", content: `De gebruiker zei: ${transcription}. De gebruiker wordt beschreven als volgt: ${latestImageDescription}`});
    

    let text='';
    let quiz = false;
    if (LLMService === 'openai'){
      // Getting the base64 string
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponse`, {messages: messages});
      text = response.data.response;
    } else if (LLMService === 'groq'){
      // ############ GROQ toepassing
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponseGroq`, {messages: messages});
      text = response.data.response;
    }
    

    else if (text !== '') {
      // Update the conversation with the response
      messages.push({role: "assistant", content: text});
      conversation=messages;

      // Speak the text
      head.speakText(text);
      //await elevenSpeak(text);
      
    }

    isAvatarThinking=false;
    isWaitingForTranscriptionResult=false;
    isWaitingForDescriptionResult=false;
    //reset de laatste opname en transcriptie.
    latestImage=null;
    latestRecording=null;

  } catch (error) {
    console.error(error);
  }
}

function pauseButton(){
    if (isAvatarThinking){
        head.start();
        setPauseButtonText('Pauze');
    } else {
        head.stop();
        setPauseButtonText('Doorgaan');
    }
    isAvatarThinking = !isAvatarThinking;
  }




function closeModal(){
  setConsentVideoMicrophone(true);
}

  return (
    <div className="App">

      <video className="videoCam" ref={videoRef} onPlay={handleVideoPlay} width="720" height="560" autoPlay muted />

      <div className="avatar-background">
        <div id="avatar"></div>
        <div id="loading"></div>
      </div>
      {recordingIndicator ? <div className='recording-indicator'> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="40" height="40"><path d="M192 0C139 0 96 43 96 96l0 160c0 53 43 96 96 96s96-43 96-96l0-160c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 89.1 66.2 162.7 152 174.4l0 33.6-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l72 0 72 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-33.6c85.8-11.7 152-85.3 152-174.4l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-40z"/></svg> Ik ben aan het luisteren..</div>: null}
      {!showPopup && <button className="pause-button button" onClick={pauseButton}>{pauseButtonText}</button>}
      {showPopup &&
        <div className="intro-modal">
          <div className="intro-modal__text">
            <div>
              <h3>Hallo, ik ben <span style={{color: '#F36F43'}}>Robin</span></h3> 
              <p className="main-text">Bedankt dat je met me komt praten, klik op 'Start' wanneer je klaar bent om te beginnen.</p>
              <ul>
                <li>Zet je geluid aan voordat je op “Start” drukt.</li>
                <li>Accepteer de aanvraag om je video te delen.</li>
                <li>Accepteer de aanvraag om je microfoon te delen.</li>
                <li>Wacht een ogenblik tot Robin je begroet</li>
                <li>Spreek met Robin nadat hij zichzelf heeft voorgesteld.</li>
              </ul>
              <p className="final-step"><span style={{color: '#F36F43'}}>Robin</span> zal je begroeten zodra hij je heeft gezien.</p>
            </div>
            <button className="button" onClick={closeModal}>Start</button>
          </div>
          <div className="intro-modal__image">
            <img src="images/InspireMax.png"/>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
