const greetingText = `Hi there! I'm Max, and I'm here to guide you through this experience. Before we dive in, let me walk you through a few quick setup steps to ensure we have a smooth conversation.
First, please make sure your sound is turned on so you can hear me clearly.
You'll see a prompt asking to share your video—please accept. This will help me see when you're ready to chat.
You'll also receive a prompt to share your microphone. Accept that as well, so I can hear you when you speak.
Keep an eye on the top of your screen—whenever you see an orange prompt appear, that means I'm listening and you can go ahead and talk to me.
If at any point you need to pause our conversation, just click the pause button. I'll freeze in place, and you can take your time. When you're ready to continue, simply press the resume button, and we'll pick up right where we left off.
After I introduce myself, feel free to ask me anything, like who I am and what my role is here at United Playgrounds.
I'll get us started in just a moment!`


export default greetingText