import axios from "axios";
import functionCall from "./functionCall";


export async function greetUserOpenAI(messages){
    let text = '';

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      };
  
      const payload = {
        "model": "gpt-4o-mini",
        "response_format": { "type": "json_object" },
        "messages": messages,
        "max_tokens": 3000
      };
  
      const response = await axios.post("https://api.openai.com/v1/chat/completions", payload, { headers });
      let json=response.data.choices[0].message.content;
      let res=JSON.parse(json);
      if (res.response) {
        console.log("User greeting: ", res.response);
        text=res.response;
      }

      return text;
}


export async function transcribeAudioOpenAI(latestRecording){
    console.log("Openai request to transcribe audio", latestRecording);
    const formData = new FormData();
    formData.append('file', latestRecording, 'recording.wav');
    formData.append('model', 'whisper-1');

    const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.text){
        return response.data.text;
    } else {
        return '';
    }
}

export async function describeImageOpenAI(messages){
  
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
    };

    const payload = {
        "model": "gpt-4o-mini",
        "response_format": { "type": "json_object" },
        "messages": messages,
        "max_tokens": 3000
    };

    const response = await axios.post("https://api.openai.com/v1/chat/completions", payload, { headers });
    let json=response.data.choices[0].message.content;
    let res=JSON.parse(json);

    if (res.response) {
        console.log("Image described successfully")
        return res.response;
    } else {
        return '';
    }
}


// Return -> text: string
export async function respondToUserOpenAI(messages){
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      };

    const payload = {
        "model": "gpt-4o-mini",
        "response_format": { "type": "json_object" },
        "messages": messages,
        "max_tokens": 3000,
      };
      
      const response = await axios.post("https://api.openai.com/v1/chat/completions", payload, { headers });

      let json=response.data.choices[0].message.content;
      let res=JSON.parse(json);
      let text = '';
      if (res.response) {
        text=res.response;
        console.log("Openai response: ", text);
      } 
      return text;
}


export async function checkQuizAnswer(question, answerOptions, answerCorrect, answerCorrectDescription, userAnswer){
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
  };

  const payload = {
      "model": "gpt-4o-mini",
      "response_format": { "type": "json_object" },
      "messages": [{role: "system", content: `Check whether the answer of the user satisfies the following quiz question:
        Question: ${question}
        The answer options given are: ${answerOptions}
        The correct answer is: ${answerCorrect}
        The description of the correct answer is: ${answerCorrectDescription}
        FORMAT: Output as JSON in the format {result:true/false}. The JSON object should contain a boolean value called 'result' indicating whether the user's answer is correct.`},
      {role: "user", content: userAnswer}],
      "max_tokens": 3000
  };

  const response = await axios.post("https://api.openai.com/v1/chat/completions", payload, { headers });
  let json=response.data.choices[0].message.content;
  let res=JSON.parse(json);

  if (res.result) {
      return res.result;
  } else {
      return '';
  }
}